import * as React from 'react'
import UiButton, { UiButtonProps } from '~/components/UiButton'

interface OwnProps {
  isLoading: boolean
}

type Props = UiButtonProps & OwnProps

function UiButtonLoader({ isLoading, disabled, children, ...props }: Props) {
  return (
    <UiButton disabled={isLoading || disabled} {...props}>
      {isLoading ? 'Loading...' : children}
    </UiButton>
  )
}

export default UiButtonLoader
