import './style'
import * as React from 'react'
import Helmet from 'react-helmet'
import UiContainer from '~/components/UiContainer'
import UiSpacer from '~/components/UiSpacer'
import AvatarSection from './AvatarSection'
import ProfileSection from './ProfileSection'
import ChangePasswordSection from './ChangePasswordSection'
import ReferralChartSection from './ReferralChartSection'
import { useHashScroll } from '~/hooks/useHashScroll'

/**
 * Use this to create a route instead of typing everything down
 */
function AppMe(props: ReactComponentWrapper) {
  useHashScroll()
  
  return (
    <React.Fragment>
      <Helmet title="Profile Settings" />

      <UiContainer size="xl">
        <UiSpacer size={5} />

        <h1>Your Profile</h1>

        <AvatarSection />
        <ProfileSection />
        <ChangePasswordSection />
        <ReferralChartSection />
      </UiContainer>
    </React.Fragment>
  )
}

export default AppMe