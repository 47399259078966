import './style.css'
import * as React from 'react'
import UiContainer from '~/components/UiContainer'
import UiCard from '~/components/UiCard'
import UiAvatar from '~/components/UiAvatar'
import UiSpacer from '~/components/UiSpacer'
import UiLevelMenu from '~/components/UiLevelMenu'
import UiButton from '~/components/UiButton'

import { useState, useEffect } from 'react'
import { useStore } from 'overstated'
import { AuthStore } from '~/stores'

import axios from '~/lib/axios'
import history from '~/lib/history'
import { usePusher } from '~/hooks/usePusher'
import ago from '~/utils/date/ago'
import monify from '~/utils/monify'
import getUserAvatar from '~/utils/users/getUserAvatar'

function ReferralSection() {
  const user = useStore(AuthStore, store => store.state.data)
  const [isLoading, setIsLoading] = useState(true)
  const [referrals, setReferrals] = useState<AppCollection<AppUser>>(null)

  useEffect(() => {
    handleRequest()
  }, [])

  usePusher(`private-App.User.${user.id}`, 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', evt => {
    if (evt.type !== 'App\\Notifications\\UserReferred') {
      return
    }

    handleRequest()
  })

  async function handleRequest() {
    setIsLoading(true)

    const [err, res] = await axios.get(`/api/referrals?page=1`)

    if (err != null) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    setReferrals(res.data)
  }

  if (isLoading || (referrals && !referrals.data.length)) {
    return <UiSpacer size={4} />
  }

  return (
    <div className="app-home-referral-section-heading">
      <UiContainer size="xl">
        <UiLevelMenu>
          <h1>Most recent referrals</h1>
          <UiButton size="small" variant="clear" onClick={() => {
            history.push('/me#referral')
          }}>
            Full List
          </UiButton>
        </UiLevelMenu>

        <UiSpacer size={4} />

        <div className="app-home-referral-section">
          {referrals &&
            referrals.data.slice(0, 4).map((referral, i) => (
              <div className="column" key={referral.id}>
                <UiCard padding={0}>
                  <div className="app-home-referral-card">
                    <div className="content">
                      <div className="avatar">
                        <UiAvatar img={getUserAvatar(referral.photo)} />
                      </div>
                      <h5 className="name">{referral.name}</h5>
                      <span className="date">{ago(referral.created_at)}</span>
                    </div>

                    <div className="footer">
                      <div className="stat">
                        <span className="icon">
                          P
                        </span>

                        <span className="number">{monify(referral.total_points)}</span>
                      </div>

                      <div className="stat">
                        <span className="icon">
                          <i className="fa fa-shopping-cart" />
                        </span>

                        <span className="number">{monify(referral.total_buys)}</span>
                      </div>

                      <div className="stat">
                        <span className="icon">
                          <i className="fa fa-shopping-bag" />
                        </span>

                        <span className="number">{monify(referral.total_sales)}</span>
                      </div>
                    </div>
                  </div>
                </UiCard>
              </div>
            ))}
        </div>
      </UiContainer>
    </div>
  )
}

export default ReferralSection
