import './style'
import * as React from 'react'
import { useState } from 'react'
import { CartStore, AuthStore } from '~/stores'
import { useStore } from 'overstated'
import UiAvatar from '~/components/UiAvatar'
import UiPlainButton from '~/components/UiPlainButton'
import UiDropdown from '~/components/UiDropdown'
import UiContainer from '~/components/UiContainer'
import Permission from '~/components/Permission'
import CheckoutConfirmationModal from './CheckoutConfirmationModal'
import { Link } from 'react-router-dom'
import history from '~/lib/history'
import { toast } from '~components/Toast'
import { usePusher } from '~/hooks/usePusher'
import getUserAvatar from '~/utils/users/getUserAvatar'

/**
 * Use this to create a route instead of typing everything down
 */
function App(props: ReactComponentWrapper) {
  const user = useStore(AuthStore, store => store.state.data)

  const cart = useStore(CartStore, store => ({
    state: store.state
  }))

  const [isOpen, setIsOpen] = useState(false)

  usePusher(`private-App.User.${user.id}`, 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', evt => {
    if (evt.type !== 'App\\Notifications\\UserReferred') {
      return
    }

    toast('A user used your referral code.')
  })

  function handleDropdownToggle() {
    setIsOpen(!isOpen)
  }

  function handleDropdownClose() {
    setIsOpen(false)
  }

  function handleClickDropdownLink(evt: React.MouseEvent<HTMLAnchorElement>) {
    evt.preventDefault()
    history.push(evt.currentTarget.getAttribute('href'))
  }

  return (
    <React.Fragment>
      <div className="app-navigation-shape">
        <UiContainer size="xl">
          <div className="app-navigation">
            <Link to="/" className="logo">
              Sheena
            </Link>
            <Link to="/" className="menu">
              Home
            </Link>

            <section className="right">
              <nav className="mininav">
                <Link to="/" className="link">
                  <i className="fa fa-search" />
                </Link>
                <Link to="/cart" className="link">
                  <div className="app-navigation-cart-icon">
                    <i className="fa fa-shopping-cart" />
                    {user.active_purchase == null && Boolean(cart.state.items.length) && (
                      <div className="count">{Math.min(cart.state.items.length, 9)}</div>
                    )}
                    {Boolean(user.active_purchase) && (
                      <div className="count is-pending">
                        <span className="fa fa-spinner" />
                      </div>
                    )}
                  </div>
                </Link>
              </nav>

              <UiDropdown
                isOpen={isOpen}
                position="bottom"
                align="end"
                content={
                  <div className="app-navigation-dropdown">
                    <div className="app-navigation-dropdown-profile">
                      <div className="avatar">
                        <UiAvatar img={getUserAvatar(user.photo)} />
                      </div>

                      <div className="info">
                        <h4>{user.name}</h4>
                        <h5 className="points">{user.total_points} Points</h5>
                      </div>
                    </div>

                    <a href="/me" className="item" onClick={handleClickDropdownLink}>
                      <span className="icon">
                        <i className="fa fa-user-o" />
                      </span>
                      Account Settings
                    </a>

                    <a href="/me#referral" className="item" onClick={handleClickDropdownLink}>
                      <span className="icon">
                        <i className="fa fa-share" />
                      </span>
                      Refer a friend
                    </a>

                    <a href="/purchases" className="item" onClick={handleClickDropdownLink}>
                      <span className="icon">
                        <i className="fa fa-shopping-bag" />
                      </span>
                      Purchase history
                    </a>

                    <Permission role={['company', 'admin']} user={user}>
                      <a href="https://dashboard.sheena.kierb.com" className="item">
                        <span className="icon">
                          <i className="fa fa-share" />
                        </span>
                        Reseller Dashboard
                      </a>
                    </Permission>

                    <a href="/logout" className="item" onClick={handleClickDropdownLink}>
                      <span className="icon">
                        <i className="fa fa-long-arrow-left" />
                      </span>
                      Logout
                    </a>
                  </div>
                }
                onClose={handleDropdownClose}>
                <UiPlainButton className="user" onClick={handleDropdownToggle}>
                  <div className="avatar">
                    <UiAvatar img={getUserAvatar(user.photo)} />
                  </div>

                  <div className="caret">
                    <i className="fa fa-angle-down" />
                  </div>
                </UiPlainButton>
              </UiDropdown>
            </section>
          </div>
        </UiContainer>
      </div>

      {props.children}

      <CheckoutConfirmationModal />
    </React.Fragment>
  )
}

export default App
