import useUpdateEffect from 'react-use/lib/useUpdateEffect'

function useAsyncUpdateEffect(effect: () => Promise<any>, destroy: () => void, deps: any[]): void {
  useUpdateEffect(() => {
    effect()
    return destroy || undefined
  }, deps)
}

export { useAsyncUpdateEffect, useAsyncUpdateEffect as default }
