import * as React from 'react'
// import { useStore } from 'overstated'
// import { AuthStore } from '~/stores'

interface Props {
  user: AppUser
  role: string | string[]
  children: any
}

function Permission(props: Props) {
  const roles = Array.isArray(props.role) ? props.role : [props.role]

  const role = React.useMemo(() => {
    return props.user.roles.find(role => roles.includes(role.code))
  }, [props.role])

  return role ? props.children : null
}

export default Permission