import './style.css'
import * as React from 'react'

interface Props {
  label: string
  children?: React.ReactNode
}

function UiFormGroup(props: Props) {
  return (
    <div className="ui-form-group">
      <label>{props.label}</label>
      {props.children}
    </div>
  )
}

export default UiFormGroup