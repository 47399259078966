import { useState, useMemo } from 'react'

type SetCallbackEvent<T> = React.FormEvent<HTMLInputElement | HTMLSelectElement> | T

type Payload<T> = {
  state: T,
  set: (name: string) => (evt: SetCallbackEvent) => void
  clear: () => void
}

type InitialState<T> = T | (() => T)

/**
 * @source https://gist.github.com/srph/2053cf063d2e0cda850055f27f0db424
 * @todo Probably not possible, but if it is, type setPassword, etc.
 */
function useFormState<T>(initial: InitialState<T>): Payload<T> {
  const [state, setState] = useState<T>(initial)
  
  return useMemo(() => {
    let out = {
      state,
      set<T>(name: string) {
        return (evt: SetCallbackEvent<T>) => {
          setState({
            ...state,
            [name]: evt.currentTarget ? evt.currentTarget.value : evt
          })
        }
      },
      clear() {
        setState(initial)
      }
    } as Payload<T>
  
    return out
  }, [state])
}

export {
  useFormState,
  useFormState as default
}