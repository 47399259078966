import './style.css'
import * as React from 'react'

interface Props {
  company: AppCompany
  companies: AppCompany[]
  onChange: (company: AppCompany) => void
}

function TitleSelect(props: Props) {  
  function handleChange(evt: React.FormEvent<HTMLSelectElement>) {
    const company = props.companies.find(company => company.id == evt.currentTarget.value)
    props.onChange(company)
  }

  return (
    <div className="app-home-title-select">
      <h2 className="heading">
        {props.company == null ? 'Your Feed' : `${props.company.name}'s Products`}
        
        <span className="caret">
          <i className="fa fa-angle-down" />
        </span>
      </h2>

      {Boolean(props.companies.length) && (
        <select className="select" value={props.company.id} onChange={handleChange}>
          {props.companies.map((company, i) => (
            <option value={company.id} key={company.id}>{company.name}</option>
          ))}
        </select>
      )}
    </div>
  )
}

export default TitleSelect