import { useEffect } from 'react'
import { AuthStore } from '~/stores'

function Logout() {
  useEffect(() => {
    AuthStore.logout()
  }, [])

  return null
}

export default Logout