import { useLayoutEffect } from 'react'
import { useRouter } from './useRouter'

/**
 * This enables hash scrolling on initial mount for routes which this is used in.
 */
function useHashScroll() {
  const router = useRouter()

  useLayoutEffect(() => {
    setTimeout(() => {
      const id = router.location.hash.replace('#', '')
      if (!id.length) return
      const el = document.getElementById(id)
      if (!el) return
      el.scrollIntoView(false)
    }, 500)
  }, [])
}

export { useHashScroll, useHashScroll as default }
