import './style.css'
import * as React from 'react'
import cx from 'classnames'

interface Props {
  id?: string
  label: string
  description: string
  narrow?: boolean
  children?: React.ReactNode
}

function UiFieldGroup(props: Props) {
  return (
    <div className={cx('ui-field-group', {
      'is-narrow': props.narrow
    })} id={props.id}>
      <div className="info">
        <h4 className="label">{props.label}</h4>
        <p className="description">{props.description}</p>
      </div>

      <div className="content">
        {props.children}
      </div>
    </div>
  )
}

export default UiFieldGroup