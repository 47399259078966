import './style'
import * as React from 'react'
import UiFieldGroup from '~/components/UiFieldGroup'
import UiFormGroup from '~/components/UiFormGroup'
import UiSpacer from '~/components/UiSpacer'
import UiInput from '~/components/UiInput'
import UiButtonLoader from '~/components/UiButtonLoader'
import UiCalendarInput from '~/components/UiCalendarInput'

import { useFormState } from '~/hooks/useFormState'
import { useStore } from 'overstated'
import { AuthStore } from '~/stores'
import { useState, useEffect } from 'react'
import axios from '~/lib/axios'
import { toast } from '~components/Toast'
import { format } from 'date-fns'

interface FormState {
  email: string
  first_name: string
  last_name: string
  phone: string
  birthdate: string
}

function ProfileSection(props) {
  const [isLoading, setIsLoading] = useState(false)

  const auth = useStore(AuthStore, store => ({
    data: store.state.data,
    updateUserData: store.updateUserData
  }))

  const form = useFormState<FormState>(() => ({
    email: auth.data.email,
    first_name: auth.data.first_name,
    last_name: auth.data.last_name,
    phone: auth.data.phone,
    // Birthdate may be `null` if the user left it blank during registration
    birthdate: auth.data.birthdate || ''
  }))

  async function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault()

    setIsLoading(true)

    const { birthdate, ...formState } = form.state

    const [err] = await axios.post('/api/me', {
      ...formState,
      birthdate: birthdate ? format(new Date(birthdate), 'YYYY-M-D') : ''
    })

    if (err != null) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    auth.updateUserData(form.state)
    toast('Your profile was successfully updated!')
  }

  return (
    <UiFieldGroup label="Profile information" description="Update your email, name, and birthdate." narrow>
      <form onSubmit={handleSubmit}>
        <UiFormGroup label="Email">
          <UiInput type="email" placeholder="john@doe.com" value={form.state.email} onChange={form.set('email')} />
        </UiFormGroup>

        <UiSpacer size={2} />

        <UiFormGroup label="First Name">
          <UiInput type="text" placeholder="John" value={form.state.first_name} onChange={form.set('first_name')} />
        </UiFormGroup>

        <UiSpacer size={2} />

        <UiFormGroup label="Last Name">
          <UiInput type="text" placeholder="Doe" value={form.state.last_name} onChange={form.set('last_name')} />
        </UiFormGroup>

        <UiSpacer size={2} />

        <UiFormGroup label="Phone">
          <UiInput type="text" placeholder="09163272133" value={form.state.phone} onChange={form.set('phone')} />
        </UiFormGroup>

        <UiSpacer size={2} />

        <UiFormGroup label="Birth Date">
          <UiCalendarInput value={form.state.birthdate} onChange={form.set('birthdate')} preset="before-today" />
        </UiFormGroup>

        <UiSpacer size={2} />

        <UiButtonLoader variant="primary" isLoading={isLoading}>
          Update profile
        </UiButtonLoader>
      </form>
    </UiFieldGroup>
  )
}

export default ProfileSection
