import './style'
import * as React from 'react'
import UiPlainButton from '~/components/UiPlainButton'

interface Props {
  min?: number
  max?: number
  value?: number
  onChange?: (value: number) => void
}

function UiNumberInput(props: Props) {
  const value = props.value || 0

  function handleDecrement() {
    if (value <= props.min) {
      return
    }

    props.onChange && props.onChange(value - 1)
  }

  function handleIncrement() {
    if (value >= props.max) {
      return
    }

    props.onChange && props.onChange(value + 1)
  }

  function handleInput(evt: React.FormEvent<HTMLInputElement>) {
    props.onChange && props.onChange(Number(evt.currentTarget.value))
  }

  return (
    <div className="ui-number-input">
      <UiPlainButton type="button" className="action" onClick={handleDecrement}>
        <i className="fa fa-minus" />
      </UiPlainButton>

      <input className="input" type="number" min={props.min} max={props.max} value={value} onChange={handleInput} />

      <UiPlainButton type="button" className="action" onClick={handleIncrement}>
        <i className="fa fa-plus" />
      </UiPlainButton>
    </div>
  )
}

UiNumberInput.defaultProps = {
  min: 0,
  max: 100
}

export default UiNumberInput
