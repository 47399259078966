import './style'
import * as React from 'react'
import Helmet from 'react-helmet'
import UiBreadcrumbs from '~/components/UiBreadcrumbs'
import UiContainer from '~/components/UiContainer'
import UiSpacer from '~/components/UiSpacer'
import UiCard from '~/components/UiCard'
import UiMoney from '~/components/UiMoney'
import UiLevelMenu from '~/components/UiLevelMenu'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'

import { useRequest } from '~/hooks/useRequest'
import useReactRouter from 'use-react-router'

interface RouteProps {
  purchaseId: string
}

function AppPurchasesView(props: ReactComponentWrapper) {
  const { match } = useReactRouter<RouteProps>()

  const [state, purchase] = useRequest<AppPurchase>(`/api/purchases/${match.params.purchaseId}`)

  if (state.isLoading || purchase == null) {
    return null
  }

  const totalCount = purchase.products.reduce((total: number, current: AppProduct) => {
    return total + current.pivot.quantity
  }, 0)

  return (
    <React.Fragment>
      <Helmet title="Order #25122" />

      <UiBreadcrumbs>
        <Link to="/purchases">Your purchase history</Link>
        <Link to={`/purchases/${match.params.purchaseId}`}>View purchase</Link>
      </UiBreadcrumbs>

      <UiContainer size="xl">
        <UiSpacer size={5} />

        <h1>{format(new Date(purchase.created_at), 'MMM D, YYYY')}</h1>

        <UiSpacer size={2} />

        <div className="app-purchases-view-layout">
          <div className="left">
            {purchase.products.map((product, i) => (
              <UiCard padding={0} key={i}>
                <div className="app-purchases-item">
                  <div className="thumbnail">
                    <img src={product.photo} />
                  </div>

                  <div className="info">
                    <h2 className="name">{product.name}</h2>
                    <div className="money">
                      <UiMoney value={product.price} />
                    </div>
                  </div>
                  <div className="qty">
                    <span className="label">Qty.</span> {product.pivot.quantity}
                  </div>
                  <div className="total">
                    <UiMoney value={Number(product.price) * product.pivot.quantity} />
                  </div>
                </div>
              </UiCard>
            ))}
          </div>

          <div className="right">
            <UiCard padding={0}>
              <UiCard.Heading>
                <UiLevelMenu>
                  <h4>Cart Summary</h4>
                  <span className="app-cart-summary-count">{totalCount} items</span>
                </UiLevelMenu>
              </UiCard.Heading>

              <UiCard.Body>
                <UiLevelMenu>
                  <h4>Total</h4>
                  <UiMoney value={purchase.amount} />
                </UiLevelMenu>
              </UiCard.Body>
            </UiCard>

            <UiSpacer size={2} />
            
            <UiCard padding={0}>
              <UiCard.Heading>
                Seller Information
              </UiCard.Heading>

              <UiCard.Body>
                <div className="app-purchases-store-profile">
                  <img src={purchase.seller.photo} className="thumbnail" />
                  <div className="info">
                    <h4>{purchase.seller.name}</h4>
                    <h6 className="sub">{purchase.seller.email}</h6>
                  </div>
                </div>
                </UiCard.Body>
              </UiCard>
          </div>
        </div>
      </UiContainer>
    </React.Fragment>
  )
}

export default AppPurchasesView
