import './style'
import * as React from 'react'

function UiLevelMenu(props: ReactComponentWrapper) {
  return (
    <div className="ui-level-menu">
      {props.children}
    </div>
  )
}

UiLevelMenu.Section = function(props: ReactComponentWrapper) {
  return (
    <div className="section">{props.children}</div>
  )
}

UiLevelMenu.SectionItem = function(props: ReactComponentWrapper) {
  return (
    <div className="item">{props.children}</div>
  )
}

export default UiLevelMenu