import { Store } from 'overstated'
import immer from 'immer'
import { toast } from '~/components/Toast'

interface Item {
  quantity: number
  product: AppProduct
}

interface State {
  items: Item[]
  sellerId: AppId
}

const constants = {
  LOCAL_KEY: 'cart_data',
  SELLER_KEY: 'seller_id'
}

class CartStore extends Store<State> {
  state = {
    items: JSON.parse(localStorage.getItem(constants.LOCAL_KEY)) || [],
    sellerId: JSON.parse(localStorage.getItem(constants.SELLER_KEY)) || -1
  }

  private async internalSetState(patch) {
    await this.setState(patch)
    localStorage.setItem(constants.LOCAL_KEY, JSON.stringify(this.state.items))
  }

  get totalCount(): number {
    return this.state.items.reduce((total: number, current: Item) => {
      return total + current.quantity
    }, 0)
  }

  get totalPrice(): number {
    return this.state.items.reduce((total: number, current: Item) => {
      return total + (Number(current.product.price) * current.quantity)
    }, 0)
  }

  getTotalProductPrice = (item: Item): number => {
    return Number(item.product.price) * item.quantity
  }

  add = (product: AppProduct, quantity: number) => {
    const index = this.state.items.map(item => item.product.id).indexOf(product.id)

    if (index === -1) {
      this.internalSetState({
        items: [...this.state.items, {
          quantity,
          product
        }]
      })
    } else {
      this.internalSetState({
        items: immer(draft => {
          draft[index].quantity += quantity
        })(this.state.items)
      })
    }

    toast(
      `Added ${quantity} of ${product.name} to the cart.`
    )
  }

  quantity = (index: number, quantity: number) => {
    this.internalSetState({
      items: immer(draft => {
        draft[index].quantity = quantity
      })(this.state.items)
    })
  }

  remove = (index: number) => {
    toast(
      `Removed ${this.state.items[index].product.name} from the cart.`
    )

    this.internalSetState({
      items: immer(draft => {
        draft.splice(index, 1)
      })(this.state.items)
    })
  }

  clear = () => {
    this.internalSetState({
      items: []
    })
  }

  clearSellerId = () => {
    this.setState({ sellerId: -1 })
    localStorage.removeItem(constants.SELLER_KEY)
  }

  setSellerId = (sellerId: AppId) => {
    this.setState({ sellerId })
    localStorage.setItem(constants.SELLER_KEY, JSON.stringify(sellerId))
  }
}

export default new CartStore()