import './style.css'
import * as React from 'react'
import { useState } from 'react'
import cx from 'classnames'
import UiPlainButton from '~/components/UiPlainButton'

type ElementProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className' | 'size'>

interface OwnProps {
  size?: 'small' | 'medium' | 'large'
  action: React.ReactNode
  onActionClick?: () => void
}

type Props = ElementProps & OwnProps

function UiInputGroup({ size, action, onActionClick, ...props }: Props, ref) {
  const [isFocused, setIsFocused] = useState<boolean>(false)

  function handleFocus(evt: React.FocusEvent<HTMLInputElement>) {
    setIsFocused(true)
    props.onFocus && props.onFocus(evt)
  }

  function handleBlur(evt: React.FocusEvent<HTMLInputElement>) {
    setIsFocused(false)
    props.onBlur && props.onBlur(evt)
  }

  return (
    <div className={cx('ui-input-group', {
      'is-focused': isFocused
    })}>
      <input {...props} onFocus={handleFocus} onBlur={handleBlur} ref={ref} />

      <UiPlainButton type="button" className="action" onClick={onActionClick}>
        {action}
      </UiPlainButton>
    </div>
  )
}

export default React.forwardRef(UiInputGroup)
