import './style'
import * as React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import UiFormGroup from '~/components/UiFormGroup'
import UiInput from '~/components/UiInput'
import UiSpacer from '~/components/UiSpacer'
import UiCalendarInput from '~/components/UiCalendarInput'
import UiButtonLoader from '~/components/UiButtonLoader'

import { useState } from 'react'
import { useFormState } from '~/hooks/useFormState'
import { AuthStore, CartStore } from '~/stores'
import { toast } from '~/components/Toast'
import axios from '~/lib/axios'
import history from '~/lib/history'
import pusher from '~/lib/pusher'
import { format } from 'date-fns'

interface FormState {
  email: string
  password: string
  password_confirmation: string
  first_name: string
  last_name: string
  phone: string
  birthdate: string
  code: string
}

/**
 * Use this to create a route instead of typing everything down
 */
function Register(props: ReactComponentWrapper) {
  const [isLoading, setIsLoading] = useState(false)

  const [error, setError] = useState('')
  
  const form = useFormState<FormState>({
    email: '',
    password: '',
    password_confirmation: '',
    first_name: '',
    last_name: '',
    phone: '',
    birthdate: '',
    code: ''
  })

  async function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault()

    setIsLoading(true)
    setError('')

    const { birthdate, ...formState } = form.state

    const [err] = await axios.post('/api/register', {
      ...formState,
      // We don't want to send `birthdate` if it's not filled. Otherwise, the API
      // will give an error along the lines of "Birthdate is not a valid date".
      ...(birthdate.length ? { birthdate: format(new Date(birthdate), 'YYYY-M-D') } : {})
    })

    if (err != null ){
      setIsLoading(false)
      setError('An error occurred while creating your account.')
      return
    }

    const [err2] = await AuthStore.login({
      username: form.state.email,
      password: form.state.password
    })

    if (err2) {
      setIsLoading(false)
      setError('An error occurred while creating your account.')
    }

    CartStore.clear()
    CartStore.clearSellerId()
    pusher.unset()

    toast('Your account was created successfully!')

    setIsLoading(false)

    history.push('/')
  }

  return (
    <React.Fragment>
      <Helmet title="Create an account" />

      <div className="register-layout">
        <div className="left">
          &nbsp;
        </div>

        <div className="right">
          <h1 className="register-logo">Sheena</h1>  
          <h2 className="register-text">Create your account, for free</h2>

          <div className="register-form">
            {Boolean(error) && (
              <p className="register-error">{error}</p>
            )}

            <form onSubmit={handleSubmit}>
              <UiFormGroup label="Email">
                <UiInput value={form.state.email} onChange={form.set('email')} type="text" placeholder="johndoe" size="large" />
              </UiFormGroup>

              <UiSpacer size={2} />

              <UiFormGroup label="Password">
                <UiInput value={form.state.password} onChange={form.set('password')} type="password" placeholder="********" size="large" />
              </UiFormGroup>

              <UiSpacer size={2} />

              <UiFormGroup label="Password confirmation">
                <UiInput value={form.state.password_confirmation} onChange={form.set('password_confirmation')} type="password" placeholder="********" size="large" />
              </UiFormGroup>

              <UiSpacer size={2} />

              <UiFormGroup label="First Name">
                <UiInput value={form.state.first_name} onChange={form.set('first_name')} type="text" placeholder="John" size="large" />
              </UiFormGroup>

              <UiSpacer size={2} />

              <UiFormGroup label="Last Name">
                <UiInput value={form.state.last_name} onChange={form.set('last_name')} type="text" placeholder="Doe" size="large" />
              </UiFormGroup>

              <UiSpacer size={2} />

              <UiFormGroup label="Phone">
                <UiInput value={form.state.phone} onChange={form.set('phone')} type="text" placeholder="09163272133" size="large" />
              </UiFormGroup>

              <UiSpacer size={2} />

              <UiFormGroup label="Birthdate">
                <UiCalendarInput value={form.state.birthdate} onChange={form.set('birthdate')} preset="before-today" size="large" />
              </UiFormGroup>

              <UiSpacer size={2} />

              <UiFormGroup label="Referral Code (Optional)">
                <UiInput value={form.state.code} onChange={form.set('code')} type="text" placeholder="Enter referral code" size="large" />
              </UiFormGroup>

              <UiSpacer size={3} />

              <UiButtonLoader variant="primary" block size="large" isLoading={isLoading}>
                Create an account
              </UiButtonLoader>
            </form>
          </div>

          <footer className="register-footer">Already have an account? <Link to="/login">Sign in</Link>.</footer>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Register