import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './style.css'
import * as React from 'react'
import cx from 'classnames'
import { useMemo, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import moment = require('moment')

interface Props {
  size?: 'medium' | 'large'
  placeholder?: string
  value: string
  onChange: (date: string) => void
  preset?: 'before-today' | 'after-today'
}

function UiCalendarInput(props: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const date = useMemo(() => {
    return Boolean(props.value) ? moment(props.value) : null
  }, [props.value])

  const today = useMemo(() => {
    return moment()
  }, [])

  function handleChange(date: moment.Moment) {
    props.onChange(date ? date.toISOString() : '')
  }

  function handleFocusChange({ focused }) {
    setIsOpen(focused)
  }

  function isOutsideRange(date) {
    if (props.preset === 'before-today') {
      return date.isAfter(today)
    }
    
    if (props.preset === 'after-today') {
      return date.isBefore(today)
    }
  }

  return (
    <div className={cx('ui-calendar-input', {
      'is-large': props.size === 'large'
    })}>
      <SingleDatePicker
        date={date}
        onDateChange={handleChange}
        focused={isOpen}
        onFocusChange={handleFocusChange}
        hideKeyboardShortcutsPanel
        isOutsideRange={isOutsideRange}
        placeholder="11/29/1996"
      />
    </div>
  )
}

export default UiCalendarInput
