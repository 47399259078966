import './style.css'
import * as React from 'react'
import cx from 'classnames'

type ElementProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className' | 'size'>

interface OwnProps {
  size?: 'small' | 'medium' | 'large'
}

type Props = ElementProps & OwnProps

function UiInput({ size, ...props }: Props) {
  return (
    <input {...props} className={cx('ui-input', {
      'is-large': size === 'large'
    })} />
  )
}

export default UiInput
