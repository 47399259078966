import { useEffect } from 'react'

function useAsyncEffect(effect: () => Promise<any>, destroy: () => void, deps: any[]): void {
  useEffect(() => {
    effect()
    return destroy || undefined
  }, deps)
}

export { useAsyncEffect, useAsyncEffect as default }
