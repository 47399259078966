import './style'
import * as React from 'react'
import UiAvatar from '~/components/UiAvatar'
import UiFieldGroup from '~/components/UiFieldGroup'
import UiSpacer from '~/components/UiSpacer'
import UiCard from '~/components/UiCard'
import UiInputGroup from '~/components/UiInputGroup'
import UiTable from '~/components/UiTable'
import UiLevelMenu from '~/components/UiLevelMenu'
import UiPagination from '~/components/UiPagination'
import UiSpinner from '~/components/UiSpinner'
import getUserAvatar from '~/utils/users/getUserAvatar'

import { usePusher } from '~/hooks/usePusher'
import { useStore } from 'overstated'
import { AuthStore } from '~/stores'
import { useState, useEffect, useRef } from 'react'
import axios from '~/lib/axios'

import monify from '~/utils/monify'
import ago from '~/utils/date/ago'
import { toast } from '~components/Toast'

/**
 * Use this to create a route instead of typing everything down
 */
function ReferralChartSection(props) {
  const user = useStore(AuthStore, store => store.state.data)
  const [isLoading, setIsLoading] = useState(true)
  // const [isInitialized, setIsInitialized] = useState(true)
  const [referrals, setReferrals] = useState<AppCollection<AppUser>>(null)
  const referralRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    handleRequest()
  }, [])

  usePusher(`private-App.User.${user.id}`, 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', evt => {
    if (evt.type !== 'App\\Notifications\\UserReferred') {
      return
    }

    handleRequest()
  })

  async function handleRequest(page: number = 1) {
    setIsLoading(true)

    const [err, res] = await axios.get(`/api/referrals?page=${page}`)

    if (err != null) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    setReferrals(res.data)
  }

  function handlePageChange(page: number) {
    setReferrals({
      ...referrals,
      current_page: page
    })

    handleRequest(page)
  }

  function handleCodeCopy() {
    referralRef.current.select()
    document.execCommand('copy')
    toast('Referral code was copied to clipboard.')
  }

  return (
    <UiFieldGroup
      id="referral"
      label="Referral chart"
      description="Here you may view your parent account and  the accounts that used your referral code upon registration."
      narrow>
      <div className="app-me-referral-chart">
        {Boolean(user.referrer) && (
          <React.Fragment>
            <div aria-label={`${user.referrer.name} (Referrer)`} data-balloon-pos="up">
              <UiAvatar img={getUserAvatar(user.referrer.photo)} />
            </div>
            <div className="line" />
          </React.Fragment>
        )}

        <div className="self" aria-label={`${user.name} (You)`} data-balloon-pos="up">
          <UiAvatar img={getUserAvatar(user.photo)} />
        </div>
      </div>

      {Boolean(referrals) && !referrals.data.length && (
        <UiCard>
          <div className="app-me-referral-chart-empty">
            <p className="description">It looks like nobody has use your referral code yet.</p>
            <UiInputGroup
              ref={referralRef}
              value={user.referral_code}
              readOnly
              action="Copy"
              onActionClick={handleCodeCopy}
            />
          </div>
        </UiCard>
      )}

      {Boolean(referrals) && Boolean(referrals.data.length) && (
        <React.Fragment>
          <UiCard padding={0} elevated>
            <UiCard.Heading subtle>
              <UiLevelMenu>
                <h4>Referred users</h4>

                <div className="app-me-referral-chart-side">
                  <UiInputGroup
                    ref={referralRef}
                    value={user.referral_code}
                    readOnly
                    action="Copy"
                    onActionClick={handleCodeCopy}
                  />
                </div>
              </UiLevelMenu>
            </UiCard.Heading>

            <UiTable data={referrals.data}>
              <UiTable.Column width={32}>{data => <UiAvatar img={getUserAvatar(data.photo)} />}</UiTable.Column>

              <UiTable.Column>
                {data => (
                  <React.Fragment>
                    <p>{data.name}</p>
                    <span className="app-me-referral-table-refer-date">{ago(data.created_at)}</span>
                  </React.Fragment>
                )}
              </UiTable.Column>

              <UiTable.Column heading="P">{data => monify(data.total_points)}</UiTable.Column>

              <UiTable.Column heading={<i className="fa fa-shopping-cart" />}>
                {data => monify(data.total_buys)}
              </UiTable.Column>

              <UiTable.Column heading={<i className="fa fa-shopping-bag" />}>
                {data => monify(data.total_sales)}
              </UiTable.Column>
            </UiTable>
          </UiCard>

          <UiSpacer />

          <UiLevelMenu>
            <div />

            <UiLevelMenu.Section>
              <UiLevelMenu.SectionItem>{isLoading && <UiSpinner />}</UiLevelMenu.SectionItem>

              <UiLevelMenu.SectionItem>
                <UiPagination collection={referrals} onPageChange={handlePageChange} />
              </UiLevelMenu.SectionItem>
            </UiLevelMenu.Section>
          </UiLevelMenu>
        </React.Fragment>
      )}

      <UiSpacer size={10} />
    </UiFieldGroup>
  )
}

export default ReferralChartSection
