import './style'
import * as React from 'react'
import cx from 'classnames'
import UiFieldGroup from '~/components/UiFieldGroup'
import UiButton from '~/components/UiButton'
import UiPlainButton from '~/components/UiPlainButton'
import UiSpinner from '~/components/UiSpinner'
import Uploadi from '@srph/react-uploadi'

import tofd from 'tofd'
import { useStore, Store } from 'overstated'
import { AuthStore } from '~/stores'
import { useState } from 'react'
import { toast } from '~/components/Toast'
import axios from '~/lib/axios'

import asset_defaultAvatar from '~/assets/default-avatar.jpg'

/**
 * Use this to create a route instead of typing everything down
 */
function AvatarSection(props) {
  const auth = useStore<Partial<typeof AuthStore>>(AuthStore, store => ({
    state: store.state,
    updateUserData: store.updateUserData
  }))
  const [avatar, setAvatar] = useState<string>(auth.state.data.photo)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function handleUpload(file: File, img: string) {
    setAvatar(img)
    setIsLoading(true)

    const [err, res] = await axios.post('/api/me/photo', tofd({ photo: img }))

    if (err != null) {
      setAvatar(auth.state.data.photo)
      setIsLoading(false)
      return
    }
    
    setIsLoading(false)
    auth.updateUserData({ photo: res.data.path })
    toast('Your avatar was successfully updated!')
  }

  return (
    <UiFieldGroup label="Upload an avatar" description="Choose a photo of you that represents you the most.">
      <Uploadi onFiles={handleUpload} disabled={isLoading}>
        {({ over, onSelect }) => {
          function handleSelect() {
            if (isLoading) {
              return
            }

            onSelect()
          }

          return (
            <div
              className={cx('app-me-uploader', {
                'is-over': !isLoading && over,
                'is-uploading': isLoading
              })}>
              {!avatar && (
                <React.Fragment>
                  <div className="icon">
                    <i className="fa fa-photo" />
                  </div>

                  <p className="description">Drag and drop or select a 250x250 photo</p>

                  <UiButton variant="primary" size="small" onClick={handleSelect}>
                    Select file
                  </UiButton>
                </React.Fragment>
              )}

              {Boolean(avatar) && (
                <React.Fragment>
                  <img src={avatar} className="avatar" />

                  {!isLoading && (
                    <UiPlainButton className="edit" onClick={handleSelect}>
                      <i className="fa fa-pencil" />
                    </UiPlainButton>
                  )}

                  {isLoading && (
                    <div className="spinner">
                      <UiSpinner />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          )
        }}
      </Uploadi>
    </UiFieldGroup>
  )
}

export default AvatarSection
