import * as React from 'react'
import { useStore } from 'overstated'
import { AuthStore } from '~/stores'
import { Route, Redirect } from 'react-router-dom'
import { RouteProps } from 'react-router'

function GuestRoute({ component, render, ...rest }: RouteProps) {
  const Component = component
  const auth = useStore(AuthStore, store => store)

  return (
    <Route {...rest} render={(props) => (
      auth.isGuest()
      ? (Component ? <Component {...props}  /> : render(props))
        : <Redirect to='/' />
    )} />
  )
}

export default GuestRoute