import './style'
import * as React from 'react'

/**
 * Use this to create a route instead of typing everything down
 */
function UiAlert(props: ReactComponentWrapper) {
  return (
    <div className="ui-alert">
      <div className="icon">
        <i className="fa fa-spinner" />
      </div>

      <p className="text">{props.children}</p>
    </div>
  )
}

export default UiAlert