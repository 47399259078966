import './style'
import * as React from 'react'

interface Props {
  value: number
}

/**
 * Use this to create a route instead of typing everything down
 */
function UiQty(props: Props) {
  return (
    <div className="ui-qty">
      <span className="label">Qty.</span>
      &nbsp;
      <span className="value">{props.value}</span>
    </div>
  )
}

export default UiQty