import './style'
import * as React from 'react'
import { useState } from 'react'
import { AuthStore, CartStore } from '~/stores'
import { useStore } from 'overstated'
import Helmet from 'react-helmet'
import UiContainer from '~/components/UiContainer'
import UiSpacer from '~/components/UiSpacer'
import UiLevelMenu from '~/components/UiLevelMenu'
import UiCard from '~/components/UiCard'
import UiButton from '~/components/UiButton'
import UiMoney from '~/components/UiMoney'
import UiNumberInput from '~/components/UiNumberInput'
import UiPlainButton from '~/components/UiPlainButton'
import UiAlert from '~/components/UiAlert'
import UiQty from '~/components/UiQty'
import axios from '~lib/axios'
import { toast } from '~components/Toast'

function AppCart(props: ReactComponentWrapper) {
  const auth = useStore(AuthStore, store => ({
    state: store.state,
    updateUserData: store.updateUserData
  }))

  const user = auth.state.data

  const cart = useStore(CartStore, store => ({
    totalPrice: store.totalPrice,
    totalCount: store.totalCount,
    getTotalProductPrice: store.getTotalProductPrice,
    state: store.state,
    quantity: store.quantity,
    remove: store.remove
  }))

  const [counter, setCounter] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  function handleChangeItemQuantity(index: number, quantity: number) {
    setCounter(counter + 1)
    cart.quantity(index, quantity)
  }

  function handleDeleteItem(index: number) {
    setCounter(counter + 1)
    cart.remove(index)
  }

  async function handleCheckout() {
    setIsLoading(true)

    const [err, res] = await axios.post('/api/purchases', {
      seller_id: cart.state.sellerId,
      products: cart.state.items.map(item => ({
        id: item.product.id,
        quantity: item.quantity
      }))
    })

    if (err != null) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    auth.updateUserData({ active_purchase: res.data.data })
    toast('Your cart is currently awaiting confirmation.')
  }

  return (
    <React.Fragment>
      <Helmet title="Cart" />

      <UiSpacer size={4} />

      <UiContainer size="xl">
        <UiLevelMenu>
          <h2>Manage your cart</h2>
        </UiLevelMenu>

        <UiSpacer size={4} />

        <div className="app-cart-layout">
          <div className="left">
            {cart.state.items.map((item, i) => (
              <React.Fragment key={item.product.id}>
                <UiCard padding={0}>
                  <div className="app-cart-item">
                    <div className="thumbnail">
                      <img src={item.product.photo} />
                    </div>

                    <div className="info">
                      <h2 className="name">{item.product.name}</h2>
                      <div className="money">
                        <UiMoney value={item.product.price} />
                      </div>
                    </div>
                    <div className="ticker">
                      {user.active_purchase == null && (
                        <UiNumberInput
                          min={1}
                          value={item.quantity}
                          onChange={(value: number) => handleChangeItemQuantity(i, value)}
                        />
                      )}

                      {Boolean(user.active_purchase) && (
                        <UiQty value={item.quantity} />
                      )}
                    </div>

                    <div className="total">
                      <UiMoney value={cart.getTotalProductPrice(item)} />
                    </div>

                    {user.active_purchase == null && (
                      <div className="delete">
                        <UiPlainButton type="button" className="button" onClick={() => handleDeleteItem(i)}>
                          <i className="fa fa-close" />
                        </UiPlainButton>
                      </div>
                    )}
                  </div>
                </UiCard>

                <UiSpacer size={2} />
              </React.Fragment>
            ))}
          </div>

          <div className="right">
            {Boolean(user.active_purchase) && (
              <React.Fragment>
                <UiAlert>Your purchase is currently pending confirmation from the store.</UiAlert>

                <UiSpacer />
              </React.Fragment>
            )}

            <UiCard padding={0}>
              <UiCard.Heading>
                <UiLevelMenu>
                  <h4>Cart Summary</h4>
                  <span className="app-cart-summary-count">{cart.totalCount} Items</span>
                </UiLevelMenu>
              </UiCard.Heading>

              <UiCard.Body>
                <UiLevelMenu>
                  <h4>Total</h4>
                  <UiMoney value={cart.totalPrice} />
                </UiLevelMenu>
              </UiCard.Body>
            </UiCard>

            <UiSpacer size={2} />

            {user.active_purchase == null && (
              <UiButton
                type="button"
                variant="primary"
                block
                disabled={!cart.state.items.length || isLoading}
                onClick={handleCheckout}>
                {isLoading ? 'Checking out...' : 'Proceed to Checkout'}
              </UiButton>
            )}
          </div>
        </div>
      </UiContainer>
    </React.Fragment>
  )
}

export default AppCart
