import './style'
import * as React from 'react'
import Popover, { Position, Align } from 'react-tiny-popover'

interface Props {
  isOpen: boolean
  position?: Position
  align?: Align
  content: JSX.Element
  children: JSX.Element
  onClose: () => void
}

/**
 * Use this to create a route instead of typing everything down
 */
function UiDropdown(props: Props) {
  return (
    <Popover
      isOpen={props.isOpen}
      position={props.position}
      onClickOutside={props.onClose}
      align={props.align}
      disableReposition={false}
      padding={8}
      content={<div className="ui-dropdown">{props.content}</div>}>
      {props.children}
    </Popover>
  )
}

export default UiDropdown
