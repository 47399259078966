import './style'
import * as React from 'react'
import { useState } from 'react'
import { useAsyncEffect } from '~/hooks/useAsyncEffect'
import { useAsyncUpdateEffect } from '~/hooks/useAsyncUpdateEffect'
import { useUpdateDebounce } from '~/hooks/useUpdateDebounce'
import axios from '~/lib/axios'

import Helmet from 'react-helmet'
import UiContainer from '~/components/UiContainer'
import UiSpacer from '~/components/UiSpacer'
import UiLevelMenu from '~/components/UiLevelMenu'
import UiMoney from '~/components/UiMoney'
import UiPagination from '~/components/UiPagination'
import UiPaginationInfo from '~/components/UiPaginationInfo'
// import UiInputGroup from '~/components/UiInputGroup'
import { Link } from 'react-router-dom'
import TitleSelect from './TitleSelect'
import ReferralSection from './ReferralSection'

import { useStore } from 'overstated'
import { CartStore } from '~/stores'

interface State {
  companies: AppCompany[]
  product: AppCollection<AppProduct>
  isCompaniesLoading: false
  isProductsLoading: false
}

function AppHome(props: ReactComponentWrapper) {
  const cart = useStore(CartStore, store => ({
    state: store.state,
    setSellerId: store.setSellerId
  }))

  const [search, setSearch] = useState<string>('')
  const [companies, setCompanies] = useState<AppCompany[]>([])
  const [products, setProducts] = useState<AppCollection<AppProduct>>(null)
  const [selectedCompany, setSelectedCompany] = useState<AppCompany>(null)
  const [isCompaniesLoading, setIsCompaniesLoading] = useState<boolean>(true)
  const [isProductsLoading, setIsProductsLoading] = useState<boolean>(false)

  useAsyncEffect(async () => {
    setIsCompaniesLoading(true)

    const [err, res] = await axios.get('/api/companies')

    if (err != null) {
      // @TODO
    }

    if (cart.state.sellerId === -1) {
      const company = res.data.data[0]
      setSelectedCompany(company)
      cart.setSellerId(company.id)
    } else {
      const company = res.data.data.find(company => company.id === cart.state.sellerId)
      setSelectedCompany(company)
    }

    setCompanies(res.data.data)
    setIsCompaniesLoading(false)
  }, null, [])

  useAsyncUpdateEffect(async () => {
    handleRequestProducts()
  }, null, [selectedCompany])

  useUpdateDebounce(() => {
    handleRequestProducts(1)
  }, 250, [search])

  async function handleRequestProducts(page: number = 1) {
    setIsProductsLoading(true)

    const [err, res] = await axios.get(`/api/users/${selectedCompany.referral_code}/products?page=${page}&search=${search}`)

    if (err != null) {
      // @TODO
    }

    setProducts(res.data)
    setIsProductsLoading(false)
  }

  function handleChangeCompany(company: AppCompany) {
    if (cart.state.items.length) {
      alert('Please clear your cart before switching stores!')
      return
    }

    setSelectedCompany(company)
    cart.setSellerId(company.id)
  }

  function handleChangePage(page: number) {
    setProducts({
      ...products,
      current_page: page
    })

    handleRequestProducts(page)
  }

  function handleSearch(evt: React.FormEvent<HTMLInputElement>) {
    setSearch(evt.currentTarget.value)
  }

  const title = selectedCompany == null ? 'Your Feed' : `${selectedCompany.name}'s Products`

  return (
    <React.Fragment>
      <Helmet title={title} />

      <ReferralSection />

      <UiContainer size="xl">
        <UiLevelMenu>
          <TitleSelect company={selectedCompany} companies={companies} onChange={handleChangeCompany} />

          {Boolean(products) && (
            <UiLevelMenu.Section>
              <UiLevelMenu.SectionItem>
                <UiPaginationInfo collection={products} />
              </UiLevelMenu.SectionItem>

              {/* <UiLevelMenu.SectionItem>
                <UiInputGroup placeholder="Search for a product..." action={<i className="fa fa-search" />} onChange={handleSearch} />
              </UiLevelMenu.SectionItem> */}
            </UiLevelMenu.Section>
          )}
        </UiLevelMenu>

        <UiSpacer size={4} />

        <div className="home-promo-list">
          {Boolean(products) && products.data.map((product, i) => (
            <Link to={`/p/${product.id}`} className="column" key={product.id}>
              <div className="home-promo-item">
                <img src={product.photo} className="thumbnail" />
                <div className="overlay" />

                <div className="price">
                  <UiMoney mode="light" value={product.price} />
                </div>

                <div className="info">
                  <h2 className="name">{product.name}</h2>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {Boolean(products) && (
          <UiLevelMenu>
            <UiPaginationInfo collection={products} />

            <UiPagination collection={products} onPageChange={handleChangePage} />
          </UiLevelMenu>
        )}

        <UiSpacer size={10} />
      </UiContainer>
    </React.Fragment>
  )
}

export default AppHome
