import './style'
import * as React from 'react'
import Helmet from 'react-helmet'
import UiContainer from '~/components/UiContainer'
import UiSpacer from '~/components/UiSpacer'
import UiCard from '~/components/UiCard'
import UiMoney from '~/components/UiMoney'
import UiLevelMenu from '~/components/UiLevelMenu'
import UiPagination from '~/components/UiPagination'
import UiPaginationInfo from '~/components/UiPaginationInfo'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'

import axios from '~/lib/axios'
import { useState, useEffect } from 'react'

/**
 * Use this to create a route instead of typing everything down
 */
function AppPurchasesView(props: ReactComponentWrapper) {
  const [isLoading, setIsLoading] = useState(true)
  // const [isInitialized, setIsInitialized] = useState(true)
  const [purchases, setPurchases] = useState<AppCollection<AppPurchase>>(null)

  useEffect(() => {
    handleRequest()
  }, [])

  async function handleRequest(page: number = 1) {
    setIsLoading(true)

    const [err, res] = await axios.get(`/api/purchases?page=${page}`)

    if (err != null) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    setPurchases(res.data)
  }

  function handlePageChange(page: number) {
    setPurchases({
      ...purchases,
      current_page: page
    })

    handleRequest(page)
  }

  return (
    <React.Fragment>
      <Helmet title="Your purchase history" />

      <UiContainer size="xl">
        <UiSpacer size={5} />

        <h1>Your purchase history</h1>

        <UiSpacer size={2} />

        {Boolean(purchases) &&
          purchases.data.map((purchase, i) => (
            <React.Fragment key={purchase.id}>
              <UiCard padding={0}>
                <UiCard.Heading>
                  <UiLevelMenu>
                    <h3>{format(new Date(purchase.created_at), 'MMM D, YYYY')}</h3>
                    <Link to={`/purchases/${purchase.id}`} className="app-purchase-item-button">
                      View purchase
                      <span className="icon">
                        <i className="fa fa-angle-right" />
                      </span>
                    </Link>
                  </UiLevelMenu>
                </UiCard.Heading>

                <UiCard.Body>
                  {purchase.products.map((product, j) => (
                    <div className="app-purchases-item" key={product.id}>
                      <div className="thumbnail">
                        <img src={product.photo} />
                      </div>

                      <div className="info">
                        <h2 className="name">{product.name}</h2>
                        <div className="money">
                          <UiMoney value={product.price} />
                        </div>
                      </div>
                      <div className="qty">
                        <span className="label">Qty.</span> {product.pivot.quantity}
                      </div>
                      <div className="total">
                        <UiMoney value={Number(product.price) * product.pivot.quantity} />
                      </div>
                    </div>
                  ))}
                </UiCard.Body>
              </UiCard>

              <UiSpacer size={2} />
            </React.Fragment>
          ))}

        {Boolean(purchases) && (
          <UiLevelMenu>
            <UiPaginationInfo collection={purchases} />

            <UiLevelMenu.Section>
              <UiLevelMenu.SectionItem>
                <UiPagination collection={purchases} onPageChange={handlePageChange} />
              </UiLevelMenu.SectionItem>
            </UiLevelMenu.Section>
          </UiLevelMenu>
        )}
      </UiContainer>

      <UiSpacer size={10} />
    </React.Fragment>
  )
}

export default AppPurchasesView
