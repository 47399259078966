import './style'
import * as React from 'react'
import UiFieldGroup from '~/components/UiFieldGroup'
import UiFormGroup from '~/components/UiFormGroup'
import UiSpacer from '~/components/UiSpacer'
import UiInput from '~/components/UiInput'
import UiButtonLoader from '~/components/UiButtonLoader'

import { useFormState } from '~/hooks/useFormState'
import { useState } from 'react'
import axios from '~/lib/axios'
import { toast } from '~components/Toast'

interface FormState {
  password: string
  password_confirmation: string
}

function ChangePasswordSection(props) {
  const [isLoading, setIsLoading] = useState(false)

  const form = useFormState<FormState>(() => ({
    password: '',
    password_confirmation: ''
  }))

  async function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault()

    setIsLoading(true)

    const [err] = await axios.post('/api/me', form.state)

    if (err != null) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    form.clear()
    toast('Your password was successfully updated!')
  }

  return (
    <UiFieldGroup label="Change password" description="Keep yourself secured. Friendly reminder not to share this password with anyone." narrow>
      <form onSubmit={handleSubmit}>
        <UiFormGroup label="Password">
          <UiInput type="password" placeholder="********" value={form.state.password} onChange={form.set('password')} />
        </UiFormGroup>

        <UiSpacer size={2} />

        <UiFormGroup label="Password Confirmation">
          <UiInput type="password" placeholder="********" value={form.state.password_confirmation} onChange={form.set('password_confirmation')} />
        </UiFormGroup>

        <UiSpacer size={2} />

        <UiButtonLoader variant="primary" isLoading={isLoading}>
          Update profile
        </UiButtonLoader>
      </form>
    </UiFieldGroup>
  )
}

export default ChangePasswordSection
