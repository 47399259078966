import './style.css'
import * as React from 'react'
import { useMemo } from 'react'
import UiPlainButton from '~/components/UiPlainButton'
import cx from 'classnames'
import range from '~/utils/range';

interface Props {
  collection: AppCollection<any>
  onPageChange: (page: number) => void
}

function UiPagination(props: Props) {
  const pages = useMemo(() => {
    const last = Math.min(props.collection.current_page + 7, props.collection.last_page)
    return range(props.collection.current_page, last)
  }, [props.collection.last_page])

  function handlePrevious() {
    const previous = Math.max(1, props.collection.current_page - 1)
    props.onPageChange(previous)
  }

  function handleNext() {
    const next = Math.min(props.collection.last_page, props.collection.current_page + 1)
    props.onPageChange(next)
  }

  return (
    <div className="ui-pagination">
      <UiPlainButton className="page" disabled={props.collection.current_page === 1} onClick={handlePrevious}>
        <i className="fa fa-angle-left" />
      </UiPlainButton>

      {pages.map((page) => (
        <UiPlainButton className={cx('page', {
          'is-active': page === props.collection.current_page
        })} key={page} onClick={() => props.onPageChange(page)}>
          {page}
        </UiPlainButton>
      ))}

      <UiPlainButton className="page" disabled={props.collection.current_page === props.collection.last_page} onClick={handleNext}>
        <i className="fa fa-angle-right" />
      </UiPlainButton>
    </div>
  )
}

export default UiPagination