import 'sanitize.css'
import 'font-awesome/css/font-awesome.css'
import 'balloon-css'
import './global.css'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import { GuestRoute, PrivateRoute } from '~/components/RoutePermission'
import { Provider as OverstatedProvider } from 'overstated'
import history from '~/lib/history'
import config from '~/config'

import { AuthStore } from '~/stores'
import { useAsyncEffect } from '~/hooks/useAsyncEffect'

import App from '~/screens/app'
import AppHome from '~/screens/app.home'
import AppCart from '~/screens/app.cart'
import AppProduct from '~/screens/app.product'
import AppMe from '~/screens/app.me'
import AppPurchases from '~/screens/app.purchases'
import AppPurchasesView from '~/screens/app.purchases-view'
import Login from '~/screens/login'
import Logout from '~/screens/logout'
import Register from '~/screens/register'
import Helmet from 'react-helmet'
import { Toast } from '~/components/Toast'

/**
 * Block rendering and load the user data
 *
 * We're explicitly returning React.ReactElement here because
 * Typescript has issues
 */
function Root(props: ReactComponentWrapper) {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  useAsyncEffect(
    async () => {
      const [x, y] = await AuthStore.getUserData()
      setIsLoading(false)
    },
    null,
    []
  )

  // We'll wrap in fragment, otherwise we'll get an error saying:
  // JSX element type '{}' is not a constructor function for JSX elements.
  return isLoading ? <div /> : <React.Fragment>{props.children}</React.Fragment>
}

function Mount() {
  return (
    <React.Fragment>
      <Helmet titleTemplate={`%s - ${config.app.title}`} />

      <OverstatedProvider>
        <Root>
          <Router history={history}>
            <Switch>
              <Route
                path="/"
                render={() => (
                  <Switch>
                    <Route path="/logout" exact component={Logout} />
                    <GuestRoute path="/login" exact component={Login} />
                    <GuestRoute path="/register" exact component={Register} />

                    <PrivateRoute
                      path="/"
                      render={() => (
                        <App>
                          <Switch>
                            <Route path="/cart" exact component={AppCart} />
                            <Route path="/p/:productId" exact component={AppProduct} />
                            <Route path="/me" exact component={AppMe} />
                            <Route path="/purchases" exact component={AppPurchases} />
                            <Route path="/purchases/:purchaseId" exact component={AppPurchasesView} />
                            <Route path="/" exact component={AppHome} />
                          </Switch>
                        </App>
                      )}
                    />
                  </Switch>
                )}
              />
            </Switch>
          </Router>
        </Root>
      </OverstatedProvider>

      <Toast />
    </React.Fragment>
  )
}

ReactDOM.render(<Mount />, document.getElementById('mount'))
