import 'slick-carousel/slick/slick.css'
import './style'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useRequest } from '~/hooks/useRequest'
import { useFormState } from '~/hooks/useFormState'
import { useStore } from 'overstated'
import { CartStore, AuthStore } from '~/stores'
import useReactRouter from 'use-react-router'

import Helmet from 'react-helmet'
import UiSpacer from '~/components/UiSpacer'
import UiLevelMenu from '~/components/UiLevelMenu'
import UiCard from '~/components/UiCard'
import UiMoney from '~/components/UiMoney'
import UiNumberInput from '~/components/UiNumberInput'
import UiButton from '~/components/UiButton'
import Slick from 'react-slick'

interface RouteProps {
  productId: string
}

function AppHome(props: RouteComponentProps) {
  const { match } = useReactRouter<RouteProps>()

  const [state, product] = useRequest<AppProduct>(`/api/products/${match.params.productId}`)

  const form = useFormState({
    quantity: 1
  })

  const cart = useStore(CartStore, store => store)

  const user = useStore(AuthStore, store => store.state.data)

  function handleAddToCart() {
    if (user.active_purchase) {
      return
    }

    cart.add(product, form.state.quantity)
  }

  if (state.isLoading || product == null) {
    return null
  }

  return (
    <React.Fragment>
      <Helmet title={`${product.name}`} />

      <UiSpacer size={4} />

      <h1 className="app-product-title">
        {product.name}
      </h1>

      <Slick
        className="app-product-slider"
        centerMode={true}
        infinite={true}
        centerPadding="60px"
        slidesToShow={3}
        speed={500}
        autoplay={false}
        focusOnSelect
        adaptiveHeight={true}
        variableWidth={true}
        arrows={false}>
        {product.images.map((image, i) =>
          <div className="app-product-slider-item" key={image.id}>
            <div className="content">
              <img src={image.path} />
            </div>
          </div>
        )}
      </Slick>

      <div className="app-product-layout">
        <div className="content">
          <h4>Description</h4>
          <p className="description">
            {product.description}
          </p>
        </div>

        <div className="details">
          <UiCard>
            <UiLevelMenu>
              <h4>Price</h4>
              <UiMoney value={product.price} />
            </UiLevelMenu>

            <UiSpacer size={3} />

            <div className="app-product-actions">
              <div className="ticker">
                <UiNumberInput value={form.state.quantity} onChange={form.set<number>('quantity')} />
              </div>

              <div className="action">
                <UiButton variant="primary" block size="small" onClick={handleAddToCart} disabled={Boolean(user.active_purchase)} aria-label={user.active_purchase && 'Your cart has a pending purchase.'}  data-balloon-pos={user.active_purchase && 'up'}>
                  Add to Cart
                </UiButton>
              </div>
            </div>
          </UiCard>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AppHome
