import './style.css'
import * as React from 'react'

interface Props {
  collection: AppCollection<any>
}

function UiPaginationInfo(props: Props) {
  return (
    <h6 className="ui-pagination-info">
      Page {props.collection.current_page} of {props.collection.last_page}
    </h6>
  )
}

export default UiPaginationInfo