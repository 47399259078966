import './style.css'
import * as React from 'react'
import UiContainer from '~/components/UiContainer'

function UiBreadcrumbs(props: ReactComponentWrapper) {
  const length = React.Children.count(props.children)

  return (
    <div className="ui-breadcrumbs-shape">
      <UiContainer size="xl">
        <div className="ui-breadcrumbs">
          {React.Children.map(props.children, (child, i) => {
            if (i === length - 1) {
              return child
            }

            return (
              <React.Fragment>
                {child}
                <div className="separator">/</div>
              </React.Fragment>
            )
          })}
        </div>
      </UiContainer>
    </div>
  )
}

export default UiBreadcrumbs