import './style.css'
import * as React from 'react'

type ColumnElement = React.ReactElement<UiTable.Column>

interface Props {
  data: any[]
  size?: 'small' | 'medium' | 'large'
  children: ColumnElement[]
}

function UiTable(props: Props) {
  return (
    <table className="ui-table">
      <thead>
        <tr>
          {React.Children.map(props.children, (child: ColumnElement) => (
            <th>{child.props.heading}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {props.data.map((data, i) => (
          <tr key={i}>
            {React.Children.map(props.children, (child: ColumnElement) => (
              <td width={child.props.width}>{child.props.children(data)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

interface ColumnProps {
  heading?: React.ReactNode
  width?: number
  children: (data: any) => React.ReactNode
}

UiTable.Column = function(props: ColumnProps) {
  return null
}

export default UiTable