import './style.css'
import * as React from 'react'
import cx from 'classnames'
import UiPlainButton from '~/components/UiPlainButton'

type ElementProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'>

interface OwnProps {
  variant?: 'primary' | 'clear'
  block?: boolean
  size?: 'small' | 'medium' | 'large'
}

type Props = ElementProps & OwnProps

function UiButton({ variant, block, size, ...props }: Props) {
  return (
    <UiPlainButton {...props} className={cx('ui-button', {
      'is-primary': variant === 'primary',
      'is-clear': variant === 'clear',
      'is-block': block,
      'is-small': size === 'small',
      'is-large': size === 'large',
    })} />
  )
}

UiButton.defaultProps = {
  block: false
}

export type UiButtonProps = Props

export default UiButton
