import './style.css'
import * as React from 'react'

interface Props {
  size?: 'small' | 'medium' | 'large'
}

function UiSpinner(props: Props) {
  return (
    <div className="ui-spinner">
      <div className="circle"></div>
    </div>
  )
}

export default UiSpinner