export default function monify(value: number) {
  value = Number(value)
  
  if (value === 0) {
    return 'N/A'
  }

  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + 'M'
  }

  if (value >= 1000) {
    return (value / 1000).toFixed(1) + 'K'
  }

  return value
}