import './style'
import * as React from 'react'
import cx from 'classnames'
import numeral = require('numeral')

interface Props {
  mode?: 'light' | 'dark'
  value: string | number
}

function UiMoney(props: Props) {
  const formatted = React.useMemo(() => {
    return numeral(props.value).format('0,0')
  }, [props.value])

  return (
    <div className={cx('ui-money', {
      'is-light': props.mode === 'light'
    })}>
      {formatted}
      <span className="currency">
        Pts
      </span>
    </div>
  )
}

export default UiMoney