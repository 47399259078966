import './style'
import * as React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import UiFormGroup from '~/components/UiFormGroup'
import UiInput from '~/components/UiInput'
import UiSpacer from '~/components/UiSpacer'
import UiButtonLoader from '~/components/UiButtonLoader'

import { useState } from 'react'
import { useFormState } from '~/hooks/useFormState'
import { AuthStore, CartStore } from '~/stores'
import pusher from '~/lib/pusher'
import history from '~/lib/history'
import { toast } from '~/components/Toast'

interface FormState {
  username: string
  password: string
}

/**
 * Use this to create a route instead of typing everything down
 */
function Login(props: ReactComponentWrapper) {
  const [isLoading, setIsLoading] = useState(false)

  const [error, setError] = useState('')
  
  const form = useFormState<FormState>({
    username: '',
    password: ''
  })

  async function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault()

    setIsLoading(true)
    setError('')

    const [err] = await AuthStore.login(form.state)

    if (err != null) {
      setIsLoading(false)
      setError('Invalid username/password combination')
      return
    }

    CartStore.clear()
    CartStore.clearSellerId()
    pusher.unset()

    toast('Login was successful.')

    setIsLoading(false)

    history.push('/')
  }

  return (
    <React.Fragment>
      <Helmet title="Welcome Back" />

      <div className="login-layout">
        <div className="left">
          &nbsp;
        </div>

        <div className="right">
          <h1 className="login-logo">Sheena</h1>  
          <h2 className="login-text">Welcome back, champion!</h2> 

          <div className="login-form">
            {Boolean(error) && (
              <p className="login-error">{error}</p>
            )}

            <form onSubmit={handleSubmit}>
              <UiFormGroup label="Username">
                <UiInput value={form.state.username} onChange={form.set('username')} type="text" placeholder="johndoe" size="large" />
              </UiFormGroup>

              <UiSpacer size={2} />

              <UiFormGroup label="Password">
                <UiInput value={form.state.password} onChange={form.set('password')} type="password" placeholder="********" size="large" />
              </UiFormGroup>

              <UiSpacer size={3} />

              <UiButtonLoader variant="primary" block size="large" isLoading={isLoading}>
                Sign in 
              </UiButtonLoader>
            </form>
          </div>

          <footer className="login-footer">New here? <Link to="/register">Sign up</Link>.</footer>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login