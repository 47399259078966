import { useState, useEffect } from 'react'
import axios from '~/lib/axios'

type Payload<T> = [
  {
    isLoading: boolean
  },
  T | null,
  {
    request: () => Promise<void>
  }
]

function useRequest<T>(endpoint: string): Payload<T> {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<T>(null)

  useEffect(() => {
    request()
  }, [])

  async function request() {
    const [err, res] = await axios.get(endpoint)

    if (err != null) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    setData(res.data)
  }

  return [{ isLoading }, data, { request }]
}

export { useRequest, useRequest as default }
