import './style'
import * as React from 'react'
import cx from 'classnames'

interface Props {
  padding?: number
  elevated?: boolean
  children: React.ReactNode
}

function UiCard(props: Props) {
  return (
    <div className={cx('ui-card', {
      'is-elevated': props.elevated
    })} style={{ padding: props.padding }}>
      {props.children}
    </div>
  )
}

interface HeadingProps {
  subtle?: boolean
  children: React.ReactNode
}

UiCard.Heading = (props: HeadingProps) => {
  return (
    <div className={cx('heading', {
      'is-subtle': props.subtle
    })}>
      {props.children}
    </div>
  )
}

UiCard.Body = (props: ReactComponentWrapper) => {
  return (
    <div className="body">
      {props.children}
    </div>
  )
}

UiCard.Footer = (props: ReactComponentWrapper) => {
  return (
    <div className="footer">
      {props.children}
    </div>
  )
}

export default UiCard