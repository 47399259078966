import './style.css'
import * as React from 'react'
import { useState } from 'react'
import UiModal from '~/components/UiModal'
import UiButton from '~/components/UiButton'
import history from '~/lib/history'
import asset_checkoutConfirmation from '~/assets/checkout-confirmation.svg'
import usePusher from '~/hooks/usePusher'

import { useStore } from 'overstated'
import { AuthStore, CartStore } from '~/stores'

function CheckoutConfirmationModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const auth = useStore(AuthStore, store => ({
    state: store.state,
    updateUserData: store.updateUserData
  }))

  const cart = useStore(CartStore, store => store)

  usePusher(`private-App.User.${auth.state.data.id}`, 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (evt) => {
    if (evt.type !== 'App\\Notifications\\PurchaseConfirmed') {
      return
    }

    setIsOpen(true)
    auth.updateUserData({ active_purchase: null })
    cart.clear()
  })

  function handleModalClose() {
    setIsOpen(false)
  }

  function handleViewOrder() {
    setIsOpen(false)
    history.push('/purchases')
  }

  return (
    <UiModal isOpen={isOpen} onClose={handleModalClose} shouldCloseOnOverlayClick={false}>
      <div className="app-checkout-confirmation-modal">
        <img src={asset_checkoutConfirmation} className="illustration" />
        <h1>Hooray!</h1>
        <p className="description">Beauty Spa just confirmed your most recent purchase.</p>
        <UiButton variant="primary" onClick={handleViewOrder}>View my order</UiButton>
      </div>
    </UiModal>
  )
}

export default CheckoutConfirmationModal